import React from "react"
import { Layout, NewsletterSection } from "@components/layout"
import { HomeAttentionSection, HomeSection } from "@components/standard"

const IndexPage = ({ pageContext, location }) => {
  const { page } = pageContext
  return (
    <Layout {...page}>
      <HomeAttentionSection />
      <HomeSection />
      <NewsletterSection />
    </Layout>
  )
}

export default IndexPage


